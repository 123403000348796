import { IndicatorValue } from "./IndicatorValue";

export class Indicator {
  private indicatorValues: IndicatorValue[];

  name: string;
  description: string;
  isPercentage: boolean;
  group: string;
  roundedDecimals: number;
  divideBy?: number;

  constructor(
    name: string,
    description: string,
    indicatorValues: IndicatorValue[],
    group: string = "",
    isPercentage: boolean = false,
    roundedDecimals: number = 0,
    divideBy?: number
  ) {
    this.name = name;
    this.description = description;
    this.isPercentage = isPercentage;
    this.indicatorValues = indicatorValues;
    this.group = group;
    this.roundedDecimals = roundedDecimals === 0 ? 1 : roundedDecimals * 10;
    this.divideBy = divideBy;
  }

  get value() {
    let value = this.indicatorValues
      .slice(1)
      .reduce(
        (acc: number, item: IndicatorValue) => acc / item.value,
        this.indicatorValues[0].value
      );

    if (this.isPercentage) {
      value *= 100;
    }

    if (this.divideBy) {
      value /= this.divideBy;
    }

    return (
      Math.round((value + Number.EPSILON) * this.roundedDecimals) /
      this.roundedDecimals
    );
  }

  get values() {
    let values = this.indicatorValues
      .slice(1)
      .reduce((acc: number[], item: IndicatorValue) => {
        return acc.map((value, index) => value / item.values[index]);
      }, this.indicatorValues[0].values);

    if (this.isPercentage) {
      values = values.map((item) => item * 100);
    }

    if (this.divideBy) {
      const abc = this.divideBy;
      values = values.map((item) => item / abc);
    }

    return values.map(
      (item) =>
        Math.round((item + Number.EPSILON) * this.roundedDecimals) /
        this.roundedDecimals
    );
  }
}
