import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdmissionProcessStep from "../AdmissionProcessStep";
import questions from "../englishTestQuestions.json";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";

export type Answers = {
  [key: string]: number;
};

export default function EnglishTest(): JSX.Element {
  const navigate = useNavigate();
  const [answers, setAnswers] = useState<Answers>({});
  const [screen, setScreen] = useState(1);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const [counter, setCounter] = useState<number>(-1);

  useEffect(() => {
    const startAtSaved =
      Number(sessionStorage.getItem("englishTestStartAt")) || Date.now();

    if (startAtSaved < Date.now() - 5 * 60 * 1000) {
      setScreen(3);
    }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }

    if (counter == 0) {
      handleNextStep();
    }
  }, [counter]);

  useEffect(() => {
    if (screen == 2) {
      const startAtSaved =
        Number(sessionStorage.getItem("englishTestStartAt")) || Date.now();

      sessionStorage.setItem("englishTestStartAt", `${startAtSaved}`);
      const newCounter = Math.round(
        (startAtSaved + 5 * 60 * 1000 - Date.now()) / 1000
      );
      if (newCounter > 0) {
        setCounter(newCounter);
      } else {
        setCounter(0);
      }
    }
  }, [screen]);

  const handleAnswerChange = (question: string, answer: number) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [question]: answer }));
  };

  const sendResults = async () => {
    const q = query(
      collection(db, "users"),
      where("email", "==", currentUser.email)
    );

    const results = Object.values(answers).reduce((acc, i) => acc + i, 0);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((snapshot) => {
      updateDoc(snapshot.ref, {
        admissionProcess: {
          1: {
            ...(snapshot?.data()?.admissionProcess?.[1] || {}),
            englishLevel: results,
            progress: 2,
          },
        },
      });
    });
  };

  const handleNextStep = async () => {
    if (screen == 2) {
      sendResults();
    }
    if (screen < 3) {
      setScreen((prevScreen) => {
        return prevScreen + 1;
      });
    } else {
      navigate("/admission-process/data-course/interview");
    }
  };

  return (
    <AdmissionProcessStep
      onNextStep={handleNextStep}
      buttonTitle={
        screen <= 2
          ? screen == 1
            ? "Comenzar test"
            : "Enviar resultados"
          : "Siguiente"
      }
    >
      {screen == 1 && (
        <div className="w-full">
          <p className="text-lg mb-6">
            ¡Enhorabuena por haber realizado la primera prueba! Acabas de dar
            tus primeros pasos en la programación con Python.
          </p>
          <p className="text-lg mb-6">
            A continuación encontrarás el test de inglés. Dispondrás de 5
            minutos para contestar el mayor número de preguntas de las 25
            totales, por lo que te recomendamos que no dediques un tiempo
            excesivo a cada pregunta.
          </p>
          <p className="text-lg">
            Ten en cuenta que comprobaremos tu nivel de inglés durante la
            entrevista, y que este no es un requisito imprescindible para ser
            admitida en el curso, por lo que te animamos a realizar el test de
            manera individual y sin consultar fuentes externas.
          </p>
        </div>
      )}
      {screen == 2 && (
        <div className="w-full">
          <div className="sticky top-0 border-gray-300 border-4 rounded-sm bg-white mb-10">
            <div className="text-primary">
              <h1 className="text-2xl text-center mb-3 font-extralight">
                El test terminará automaticamente en:
              </h1>
              <div className="text-6xl text-center flex w-full items-center justify-center">
                <div className="w-24 mx-1 p-2 bg-white text-primary rounded-lg">
                  <div className="font-mono leading-none" x-text="minutes">
                    0{Math.floor(counter / 60) || 0}
                  </div>
                  <div className="font-mono uppercase text-sm leading-none">
                    Minutos
                  </div>
                </div>
                <div className="text-2xl mx-1 font-extralight">y</div>
                <div className="w-24 mx-1 p-2 bg-white tex-primary rounded-lg">
                  <div className="font-mono leading-none" x-text="seconds">
                    {Math.floor(counter % 60) || 0}
                  </div>
                  <div className="font-mono uppercase text-sm leading-none">
                    Segundos
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-lg mb-6">
            Responde correctamente al siguiente cuestionario.
          </p>
          <div className="grid grid-cols-2 gap-8">
            {questions.map((question) => {
              return (
                <div
                  key={question.title}
                  className="grid grid-cols-1 gap-6 mt-6"
                >
                  <label className="block">
                    <span className="text-gray-700">{question.title}</span>
                    <div className="grid grid-cols-1 gap-2 mt-4">
                      {question.answers.map((answer) => {
                        return (
                          <label
                            key={answer.title}
                            className="block items-center"
                          >
                            <input
                              type="radio"
                              name={question.title}
                              value={answer.value}
                              className="border-gray-300 text-primary shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                              onChange={() =>
                                handleAnswerChange(question.title, answer.value)
                              }
                            />
                            <span className="ml-2">{answer.title}</span>
                          </label>
                        );
                      })}
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {screen == 3 && (
        <div className="w-full">
          <p className="text-lg mb-6">
            ¡Enhorabuena! Tus resultados se han enviado correctamente. Haz click
            en “siguiente” para agendar el día y hora de tu entrevista.
          </p>
        </div>
      )}
    </AdmissionProcessStep>
  );
}
