import { useEffect, useState } from "react";
import { getDocs } from "firebase/firestore";
import { Indicator } from "./models/Indicator";
import { Indicators, State } from "./models/Indicators";
import { collection } from "firebase/firestore";
import { db } from "firebase";

const indicatorsRef = collection(db, "indicators");

export const useIndicators = (
  defaultGroupBy: string[] = [],
  defaultFilterBy: string[] = []
) => {
  const [data, setData] = useState<State>({});
  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const [filteredIndicators, setFilteredIndicators] = useState<Indicator[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterBy] = useState<string[]>(defaultFilterBy);
  const [groupBy] = useState<string[]>(defaultGroupBy);

  useEffect(() => {
    getDocs(indicatorsRef).then((querySnapshot) => {
      const newData = querySnapshot.docs.reduce(
        (acc, doc) => ({ ...acc, [doc.id]: doc.data().values }),
        {}
      );
      setData(newData);
      setIndicators(Indicators.all(newData, groupBy));
      setFilteredIndicators(
        Indicators.all(newData).filter((ind) => filterBy.includes(ind.name))
      );
      setIsLoading(false);
    });
  }, [filterBy, groupBy]);

  const filterIndicatorsByName = (filterBy: string[]) => {
    setFilteredIndicators(
      Indicators.all(data).filter((ind) => filterBy.includes(ind.name))
    );
  };

  const groupIndicatorsByPromos = (promos: string[]) => {
    setIndicators(Indicators.all(data, promos));
  };

  const groupByGroup = (group: string) => {
    return indicators.filter((ind) => ind.group === group);
  };

  return {
    filteredIndicators,
    leadingIndicators: groupByGroup("leading"),
    generalIndicators: groupByGroup("general"),
    workingIndicators: groupByGroup("working"),
    isLoading,
    allIndicators: indicators.map((ind, index) => ({
      name: ind.name,
      value: ind.name,
      selected: [0].includes(index),
    })),
    filterIndicatorsByName,
    groupIndicatorsByPromos,
  };
};
