import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "App";
import ProvideAuth from "ProvideAuth";
import "./index.css";

Sentry.init({
  dsn: "https://7568bb2762d24aa6ae3a7d0e535be4fb@o1009277.ingest.sentry.io/5973359",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <ProvideAuth>
    <Router>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
  </ProvideAuth>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
