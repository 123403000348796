import React, { createContext, useContext, useState, useEffect } from "react";
import { authService } from "authService";
import User from "models/User";

type ProvideAuthState = {
  user: User | null;
  isLoading: boolean;
  signIn: (callback: () => void) => void;
  sigOut: (callback: () => void) => void;
};

type ProvideAuthProps = {
  children: React.ReactNode;
};

const AuthContext = createContext<ProvideAuthState>({
  user: null,
  isLoading: false,
  signIn: () => {},
  sigOut: () => {},
});

function useProvideAuth(): ProvideAuthState {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = authService.onSessionChanged(
      (user) => {
        setUser(user);
        setIsLoading(false);
      },
      (err) => console.error(err)
    );

    return unsubscribe;
  }, []);

  const signIn = async (callback: () => void) => {
    try {
      const user = await authService.signIn();
      if (user) {
        setUser(user);
      }
      callback();
    } catch (err) {
      console.error(err);
    }
  };

  const signOut = async (callback: () => void) => {
    try {
      await authService.signOut();
      setUser(null);
      callback();
    } catch (err) {
      console.error(err);
    }
  };

  return {
    user,
    isLoading,
    signIn: signIn,
    sigOut: signOut,
  };
}

export function useAuth() {
  return useContext(AuthContext);
}

export default function ProvideAuth({ children }: ProvideAuthProps) {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
