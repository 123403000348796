export type IndicatorData = {
  promo: string;
  value: number;
};

export class IndicatorValue {
  private groupBy: string[];
  private data: IndicatorData[];

  constructor(data: IndicatorData[], groupBy: string[]) {
    this.groupBy = groupBy;
    this.data = data;
  }

  get value() {
    return this.data
      .filter((item: IndicatorData) => this.groupBy.includes(item.promo))
      .reduce((acc: number, item: IndicatorData) => (acc += item.value), 0);
  }

  get values() {
    return this.data.map((item: IndicatorData) => item.value);
  }
}
