class User {
  private email: string | null;

  constructor(email?: string | null) {
    this.email = email || null;
  }

  isAuthenticated() {
    return this.email !== null;
  }

  isAdmin() {
    const regex = new RegExp(".*adalab[.]es");
    return this.email?.match(regex);
  }
}

export default User;
