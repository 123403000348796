export function Screen({ topBar, children }: ScreenProps) {
  return (
    <div>
      {topBar}
      <div className="container mx-auto p-4">
        <div className="flex flex-col">{children}</div>
      </div>
    </div>
  );
}

type ScreenProps = {
  children: React.ReactNode;
  topBar?: React.ReactNode;
};
