import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdmissionProcessStep from "../AdmissionProcessStep";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";

type Answers = {
  [key: string]: number | string;
};

export default function Introduction(): JSX.Element {
  const navigate = useNavigate();
  const [answers, setAnswers] = useState<Answers>({});
  const [hasErrors, setHasErrors] = useState(false);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  const handleForm = (key: string, value: string) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [key]: value }));
  };

  const handleNextStep = async () => {
    setHasErrors(false);

    if (
      Object.keys(answers).length < 7 ||
      answers["hasSimilarTimeZone"] === "0" ||
      answers["employmentStatus"] === "3" ||
      answers["studyLevel"] === "0"
    ) {
      setHasErrors(true);
    } else {
      const q = query(
        collection(db, "users"),
        where("email", "==", currentUser.email)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((snapshot) => {
        updateDoc(snapshot.ref, {
          birthdate: answers.birthdate,
          admissionProcess: {
            1: {
              ...(snapshot?.data()?.admissionProcess?.[1] || {}),
              ...Object.keys(answers)
                .filter((i) => i != "birthdate")
                .reduce((acc: any, i) => {
                  acc[i] = answers[i];
                  return acc;
                }, {}),
              progress: 1,
            },
          },
        });
      });

      navigate("/admission-process/data-course/technical-test");
    }
  };

  return (
    <AdmissionProcessStep onNextStep={handleNextStep} buttonTitle="Empezar">
      <div className="w-full">
        <p className="text-primary text-xl font-bold mb-6">
          ¡Bienvenida a Adalab Academy!
        </p>
        <p className="text-lg mb-3">
          Muchas gracias por confiar en Adalab y dedicar tu tiempo a nuestro
          proceso de admisión. Verás que es un pequeño esfuerzo comparado con
          las oportunidades que se te abrirán después de tu reinvención
          profesional :)
        </p>
        <p className="text-lg mb-3">
          Información importante a tener en cuenta (no podrás volver a esta
          pantalla):
        </p>
        <p className="text-lg mb-3">
          Puedes consultar las próximas fechas de nuestro bootcamp
          {" "}
          <a
            className="underline text-primary"
            target="_blank"
            rel="noreferrer"
            href="https://adalab.es/bootcamp-data/#calendario"
          >
            aquí
          </a>{". "}
          ¡Las plazas se agotan muy rápido, por lo que te recomendamos que realices el proceso lo antes posible!
          Puedes hacer el proceso para cualquier de nuestras promociones futuras, y en caso de ser admitida
          garantizar que tienes tu plaza en la promoción que elijas.
        </p>
        <p className="text-lg mb-3">
          Por favor revisa toda la información del curso{" "}
          <a
            className="underline text-primary"
            target="_blank"
            rel="noreferrer"
            href="https://adalab.es/bootcamp-data-analytics/"
          >
            aquí
          </a>{" "}
          o consulta nuestras{" "}
          <a
            className="underline text-primary"
            target="_blank"
            rel="noreferrer"
            href="https://adalab.es/preguntas-frecuentes/"
          >
            preguntas frecuentes.
          </a>{" "}
          Puedes conocer más sobre nuestros cursos y testimonios de adalabers en{" "}
          <a
            className="underline text-primary"
            target="_blank"
            rel="noreferrer"
            href="https://adalab.es/blog/"
          >
            nuestro blog
          </a>{" "}
          o{" "}
          <a
            className="underline text-primary"
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCYmHARvTgsUrm5YdSYwzmDg"
          >
            canal de YouTube
          </a>
          . ¡En nuestras redes tendrás toda la información!
        </p>
        <p className="text-xl text-primary font-bold my-6">
          Proceso de admisión
        </p>
        <p className="text-lg mb-3">
          Nuestro proceso de admisión tiene 3 partes, que puedes realizar en
          momentos diferentes de forma online (solamente tienes que acceder a la
          plataforma con el usuario y contraseña que te llegó por correo
          electrónico, tus resultados quedarán guardados):
        </p>
        <p className="text-lg mb-3">
          Parte 1 -{" "}
          <span className="font-bold">Tutorial y prueba de Python</span>.
          Queremos que empieces ya a experimentar con las tecnologías que
          aprenderás más adelante :) (1h30 aprox.)
        </p>
        <p className="text-lg mb-3">
          Parte 2 -{" "}
          <span className="font-bold">Prueba de nivel de Inglés.</span> (5
          minutos)
        </p>
        <p className="text-lg mb-3">
          Parte 3 - <span className="font-bold">Entrevista personal. </span>Para
          que podamos conocerte mejor, saber tus motivaciones y ver de qué forma
          podemos ayudarte a conseguir lograr tus objetivos. Ten en cuenta que
          para agendar la entrevista te pediremos que adjuntes tu CV, de manera
          a que podamos conocer un poco más de tu trayectoria profesional.
        </p>
        <p className="text-lg mb-3">
          Tardarás en completar el proceso aproximadamente 2 horas.
        </p>
        <p className="text-xl text-primary font-bold my-6">
          Requisitos técnicos
        </p>
        <p className="text-lg mb-3">
          Para hacer las pruebas debes utilizar un ordenador, estar en un
          espacio tranquilo donde puedas concentrarte y con buena señal de
          internet. Además, para la entrevista por videollamada necesitas tener
          cámara y micrófono (integrada o externa) en tu ordenador.
        </p>
        <p className="text-xl text-primary font-bold my-6">
          ¿Qué pasa después de realizar las pruebas?
        </p>
        <p className="text-lg mb-3">
          Una vez finalices el proceso de admisión, evaluaremos todas tus
          pruebas de forma conjunta y comunicaremos por email la decisión de
          admisión (si has sido admitida, te has quedado en lista de espera o no
          has sido admitida) en un plazo orientativo de una semana. Ten en
          cuenta que no hacemos públicos los resultados de las distintas
          pruebas, ya que no contamos con los recursos para hacerlo
          individualmente a cada candidata.
        </p>
        <p className="text-lg mb-3">
          En caso de que seas preadmitida tienes 2 opciones para reservar tu
          plaza en cualquier de los cursos futuros de Adalab:
        </p>
        <ul className="text-lg mb-3 list-disc pl-5">
          <li>Realizar el pago del importe total del curso (4.500€).</li>
          <li>
            Realizar el pago de la reserva de plaza (420€ no reembolsables). Si
            eliges esta opción, tendrás hasta el último día del proceso de
            admisión de tu promoción para formalizar la matrícula abonando la
            cantidad restante.
          </li>
        </ul>
        <p className="text-lg mb-3">
          Para continuar, por favor rellena la siguiente información:{" "}
        </p>

        <div className="grid grid-cols-1 gap-6 mt-6">
          <label className="block">
            <span className="text-gray-700">Nivel de estudios</span>
            <select
              defaultValue="default"
              onChange={(e) => handleForm("studyLevel", e.target.value)}
              className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="default" disabled hidden>
                Por favor, selecciona una opción
              </option>
              <option value="0">Sin estudios obligatorios</option>
              <option value="1">ESO</option>
              <option value="2">Bachillerato</option>
              <option value="3">FP Básica</option>
              <option value="4">FP Media</option>
              <option value="5">FP Superior</option>
              <option value="6">Estudios universitarios</option>
              <option value="7">Máster / Postgrado</option>
            </select>
          </label>
          {answers["studyLevel"] === "0" && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">
                En Adalab no admitimos a alumnas que no hayan terminado la ESO
                (o estudios equivalentes en su país).
              </strong>
            </div>
          )}
          <label className="block">
            <span className="text-gray-700">¿De qué son tus estudios?</span>
            <input
              onChange={(e) => handleForm("studyDetails", e.target.value)}
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder=""
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Fecha de nacimiento</span>
            <input
              onChange={(e) => handleForm("birthdate", e.target.value)}
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Código postal</span>
            <input
              onChange={(e) => handleForm("zipCode", e.target.value)}
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder=""
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              ¿Tienes conocimientos de programación?
            </span>
            <select
              defaultValue="default"
              onChange={(e) => handleForm("programmingSkills", e.target.value)}
              className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="default" disabled hidden>
                Por favor, selecciona una opción
              </option>
              <option value="0">No, ninguno</option>
              <option value="1">No, pero tengo conocimientos de estadística</option>
              <option value="2">Sí, conozco un poco de SQL</option>
              <option value="3">Sí, conozco un poco sobre cuadros de mando como Tableau o Power BI</option>
              <option value="4">Sí, conozco un poco de los fundamentos de programación como los bucles y funciones</option>
              <option value="5">Sí, he creado programas con un lenguaje de programación como Java o Python</option>
            </select>
          </label>
          <label className="block">
            <span className="text-gray-700">Situación laboral</span>
            <select
              defaultValue="default"
              onChange={(e) => handleForm("employmentStatus", e.target.value)}
              className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="default" disabled hidden>
                Por favor, selecciona una opción
              </option>
              <option value="0">
                Desempleada sin prestación por desempleo
              </option>
              <option value="1">
                Desempleada con prestación por desempleo
              </option>
              <option value="2">
                Trabajo pero lo dejaré o me pediré una excedencia para entrar en
                Adalab
              </option>
              <option value="3">
                Trabajo y no puedo dejarlo para entrar en Adalab
              </option>
            </select>
          </label>

          {answers["employmentStatus"] === "3" && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">
                En Adalab no admitimos a alumnas que estén trabajando y no
                puedan dejar su trabajo, ya que nuestros bootcamps son
                intensivos a tiempo completo.
              </strong>
            </div>
          )}
          <label className="block">
            <span className="text-gray-700">
              ¿Tu huso horario es similar al de Madrid (UTC+1)?
            </span>
            <select
              defaultValue="default"
              onChange={(e) => handleForm("hasSimilarTimeZone", e.target.value)}
              className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="default" disabled hidden>
                Por favor, selecciona una opción
              </option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </select>
          </label>

          {answers["hasSimilarTimeZone"] === "0" && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">
                En Adalab no admitimos a alumnas que no estén en un huso horario
                similar al de Madrid, ya que nuestras clases son en formato
                online en directo.
              </strong>
            </div>
          )}
        </div>
        {hasErrors && (
          <div
            className="mt-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">
              Por favor, rellena todos los campos del formulario
            </strong>
          </div>
        )}
      </div>
    </AdmissionProcessStep>
  );
}
