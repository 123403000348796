import colors from "tailwindcss/colors";

const themeColors = {
  purple: colors.violet[500],
  red: colors.red[500],
  orange: colors.amber[500],
  blue: colors.sky[500],
};

export default themeColors;
