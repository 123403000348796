import { Card } from "components";
import { CardContent } from "./Card";
import { Indicator } from "models/Indicator";

export function IndicatorList({ indicators }: IndicatorListProps) {
  return (
    <Card>
      <CardContent className="px-0">
        {indicators.map((indicator, index) => (
          <div
            key={index}
            className={`${
              index % 2 === 0 ? "bg-white" : "bg-indigo-50"
            } px-5 border-b border-gray-100 h-12 flex items-center`}
          >
            <div className="flex items-center justify-between w-full">
              <p className="text-sm leading-none">
                <span className="font-medium">
                  {indicator.value}
                  {indicator.isPercentage && (
                    <span className="text-xs font-normal">%</span>
                  )}
                </span>{" "}
                {indicator.description}.
              </p>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

type IndicatorListProps = {
  indicators: Indicator[];
};
