import { useNavigate } from "react-router-dom";
import { useAuth } from "ProvideAuth";

import image from "../assets/signIn.svg";
import icon from "../assets/googleIcon.svg";

export function LoginScreen(): JSX.Element {
  const navigate = useNavigate();
  let auth = useAuth();

  let login = () => {
    auth?.signIn(() => {
      navigate("/");
    });
  };

  return (
    <div className="bg-primary flex flex-col justify-center items-center h-screen">
      <div className="w-full md:max-w-md px-6 sm:px-16 py-12 sm:mt-24 bg-white rounded-md shadow-md flex justify-center items-center flex-col gap-12">
        <img src={image} className="w-48" alt="" />
        <button
          className="text-white bg-blue-500 pr-3 rounded-sm shadow-md w-full flex items-center justify-start gap-3"
          onClick={login}
        >
          <img src={icon} alt="" />
          Acceso con Google
        </button>
      </div>
    </div>
  );
}
