export function Card({ children, className }: CardProps) {
  return (
    <div className={`bg-white rounded-md shadow-md flex flex-col ${className}`}>
      {children}
    </div>
  );
}

export function CardContent({ children, className }: CardContentProps) {
  return <div className={`p-5 ${className}`}>{children}</div>;
}

type CardProps = {
  children: React.ReactNode;
  className?: string;
};

type CardContentProps = {
  children: React.ReactNode;
  className?: string;
};
