export function Section({ children, columns = 1 }: SectionProps) {
  const classes: ColumnClasses = {
    2: "md:grid-cols-2",
    4: "sm:grid-cols-2 md:grid-cols-4",
  };

  return (
    <div className={`grid gap-5 grid-cols-1 mt-5 ${classes[columns]}`}>
      {children}
    </div>
  );
}

type ColumnClasses = {
  [key: number]: string;
};

type SectionProps = {
  children: React.ReactNode;
  columns?: number;
};
