import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "ProvideAuth";
import { LoadingScreen } from "screens";

function RequireAuth({ children }: { children: JSX.Element }) {
  let { user, isLoading } = useAuth();
  let location = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!user?.isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default RequireAuth;
