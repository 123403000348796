import { Card, CardContent } from "components/Card";

export function Indicator({
  name,
  description,
  isPercentage = false,
  value,
  color = "#000",
}: IndicatorProps) {
  const barWidth = isPercentage && value <= 100 ? value : 100;
  const barRounding = barWidth === 100 ? "rounded-t-md" : "rounded-tl-md";

  return (
    <Card>
      <div
        className={`h-2 ${barRounding}`}
        style={{ backgroundColor: color, width: `${barWidth}%` }}
      />
      <CardContent>
        <div className="text-3xl font-medium mb-2">
          {value}
          {isPercentage && <sup className="text-base">%</sup>}
        </div>
        <h3
          className="uppercase font-bold text-xs mb-1.5"
          style={{ color: color }}
        >
          {name}
        </h3>
        <p className="text-xs font-medium">{description}</p>
      </CardContent>
    </Card>
  );
}

export type IndicatorProps = {
  name: string;
  description: string;
  isPercentage?: boolean;
  value: number;
  color: string;
};
