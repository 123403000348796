import { CheckIcon } from "components";

export function Checkbox({
  checked = false,
  className,
  name,
  onChange = () => {},
}: CheckboxProps) {
  const handleClick = () => {
    onChange(!checked);
  };

  const classes = checked ? "bg-primary" : "border-2";

  return (
    <div
      onClick={handleClick}
      className={`flex items-center justify-start gap-2 cursor-pointer ${className}`}
    >
      <div
        className={`flex-shrink-0 h-5 w-5 bg-white border-gray-400 ${classes}`}
      >
        <CheckIcon className="text-white" />
      </div>
      {name}
    </div>
  );
}

export type CheckboxProps = {
  checked?: boolean;
  name: string;
  onChange?: (checked: boolean) => void;
  className?: string;
};
