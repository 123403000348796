import { useParams } from "react-router-dom";
import { Screen } from "components";
import {
  AcademicCapIcon,
  CalendarIcon,
  PuzzleIcon,
  TranslateIcon,
  CheckIcon,
} from "components/Icons";
import IntroductionStep from "admission/steps/Introduction";
import TechnicalTestStep from "admission/steps/TechnicalTest";
import EnglishTestStep from "admission/steps/EnglishTest";
import InterviewStep from "admission/steps/Interview";
import FinalStep from "admission/steps/FinalStep";
import Stepper from "admission/Stepper";

export default function AdmissionProcess(): JSX.Element {
  let { step = "introduction" } = useParams();

  const steps: Steps = {
    introduction: {
      order: 1,
      title: "Introducción",
      description: "Bienvenida a Adalab Academy",
      element: <IntroductionStep />,
      icon: <AcademicCapIcon className="h-full w-full" />,
    },
    "technical-test": {
      order: 2,
      title: "Prueba técnica",
      description:
        "Experimenta con las tecnologías que aprenderás más adelante.",
      element: <TechnicalTestStep />,
      icon: <PuzzleIcon className="h-full w-full" />,
    },
    "english-test": {
      order: 3,
      title: "Test de inglés",
      description: "Contesta el mayor número de preguntas correctas",
      element: <EnglishTestStep />,
      icon: <TranslateIcon className="h-full w-full" />,
    },
    interview: {
      order: 4,
      title: "Entrevista",
      description: "¡Tenemos muchas ganas de conocerte!",
      element: <InterviewStep />,
      icon: <CalendarIcon className="h-full w-full" />,
    },
    final: {
      order: 5,
      title: "Has acabado",
      description: "Enhorabuena, has completado todos los tests",
      element: <FinalStep />,
      icon: <CheckIcon className="h-full w-full" />,
    },
  };

  return (
    <Screen>
      <div className="w-full text-center mt-6">
        <h1 className="text-3xl leading-loose">{steps[step].title}</h1>
        <h2 className="text-xl leading-loose">{steps[step].description}</h2>
      </div>

      <Stepper activeStep={steps[step].order} steps={steps} />
      {steps[step].element}
    </Screen>
  );
}

export type Steps = {
  [name: string]: {
    title: string;
    description: string;
    order: number;
    element: React.ReactElement;
    icon: React.ReactElement;
  };
};
