import { Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import RequireAdmissionAuth from "./RequireAdmissionAuth";
import { LoginScreen, DashboardScreen, AdmissionLoginScreen } from "screens";
import AdmissionProcess from "admission/AdmissionProcess";

export default function App(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/admission-process/data-course/:step"
        element={
          <RequireAdmissionAuth>
            <AdmissionProcess />
          </RequireAdmissionAuth>
        }
      />
      <Route
        path="/admission-process/login"
        element={<AdmissionLoginScreen />}
      />
      <Route path="/login" element={<LoginScreen />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <DashboardScreen />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
