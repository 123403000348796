import { Card, CardContent } from "components";

export default function AdmissionProcessStep({
  children,
  buttonTitle = "Siguiente",
  noButton = false,
  onNextStep,
}: AdmissionProcessStepProps): JSX.Element {
  return (
    <Card className="sm:p-6 max-w-4xl w-full m-auto">
      <CardContent className="flex justify-center">{children}</CardContent>

      {!noButton && (
        <div className="flex justify-end py-5 px-2 pt-10 w-full">
          <button
            onClick={onNextStep}
            className="bg-primary hover:bg-opacity-90 text-white font-bold py-2 px-8 rounded-full"
          >
            {buttonTitle}
          </button>
        </div>
      )}
    </Card>
  );
}

type AdmissionProcessStepProps = {
  children: React.ReactNode;
  buttonTitle?: string;
  noButton?: boolean;
  onNextStep?: () => void;
};
