import {
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
  onAuthStateChanged,
  ErrorFn,
} from "firebase/auth";
import { auth } from "firebase";
import User from "models/User";

export const authService = {
  onSessionChanged(
    callback: (user: User | null) => void,
    error?: ErrorFn,
    completed?: () => void
  ) {
    return onAuthStateChanged(
      auth,
      (data) => {
        if (data) {
          callback(new User(data.email));
        } else {
          callback(null);
        }
      },
      error,
      completed
    );
  },

  async signIn() {
    const provider = new GoogleAuthProvider();

    try {
      const data = await signInWithPopup(auth, provider);
      return new User(data.user.email);
    } catch (err) {
      return console.error(err);
    }
  },

  async signOut() {
    try {
      return signOut(auth);
    } catch (err) {
      return console.error(err);
    }
  },
};
