import { Card, CardContent } from "components";
import { Line } from "react-chartjs-2";
import { ChartData, ScatterDataPoint } from "chart.js/auto";

export function LineChart(props: LineChartProps) {
  const { data } = props;

  return (
    <Card>
      <CardContent>
        <Line data={data} />
      </CardContent>
    </Card>
  );
}

type LineChartProps = {
  data:
    | ChartData<"line", (number | ScatterDataPoint | null)[], unknown>
    | ((
        canvas: HTMLCanvasElement
      ) => ChartData<"line", (number | ScatterDataPoint | null)[], unknown>);
};
