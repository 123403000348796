import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdmissionProcessStep from "../AdmissionProcessStep";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export default function FinalStep(): JSX.Element {
  const [screen, setScreen] = useState(1);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const [cv, setCv] = useState<File>();

  const handleNextStep = async () => {
    if (screen < 2) {
      if (cv == null) return;
      upload();
      setScreen((prevScreen) => {
        return prevScreen + 1;
      });
    }
  };

  const upload = () => {
    if (cv == null) return;
    const cvRef = ref(storage, `/cv/${cv.name}`);

    uploadBytes(cvRef, cv).then((snapshot) =>
      getDownloadURL(snapshot.ref).then(async (url) => {
        const q = query(
          collection(db, "users"),
          where("email", "==", currentUser.email)
        );

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((snapshot) => {
          updateDoc(snapshot.ref, {
            admissionProcess: {
              1: {
                ...(snapshot?.data()?.admissionProcess?.[1] || {}),
                cvUrl: url,
              },
            },
          });
        });
      })
    );
  };

  useEffect(() => {
    const setStep = async () => {
      const q = query(
        collection(db, "users"),
        where("email", "==", currentUser.email)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((snapshot) => {
        if (snapshot?.data()?.admissionProcess?.[1].cvUrl) {
          setScreen(2);
        }
        updateDoc(snapshot.ref, {
          admissionProcess: {
            1: {
              ...(snapshot?.data()?.admissionProcess?.[1] || {}),
              progress: 4,
            },
          },
        });
      });
    };
    setStep();
  }, []);

  return (
    <AdmissionProcessStep
      onNextStep={handleNextStep}
      buttonTitle={screen == 1 ? "Subir" : ""}
      noButton={screen == 2}
    >
      {screen == 1 && (
        <div className="w-full">
          <p className="text-lg mb-12">¡Una última cosa!</p>
          <p className="text-lg mb-12">
            Por favor adjunta tu CV para que podamos conocer mejor tu
            trayectoria académica y profesional y lo podamos tener a mano
            durante la entrevista.
          </p>
          <div className="grid grid-cols-1 gap-6">
            <label className="block">
              <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                <div className="absolute">
                  <div className="flex flex-col items-center cursor-pointer text-gray-300">
                    <svg
                      className="w-12 h-12"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="block text-gray-400 font-normal pt-4">
                      {cv ? cv.name : "Selecciona tu curriculum"}
                    </span>
                  </div>
                </div>
                <input
                  type="file"
                  className="h-full w-full opacity-0"
                  name=""
                  onChange={(e) => setCv(e.target.files?.[0])}
                />
              </div>
            </label>
          </div>
        </div>
      )}
      {screen == 2 && (
        <div className="w-full">
          <p className="text-primary text-xl font-bold mb-6">
            ¡Enhorabuena! 🎉
          </p>
          <p className="text-lg mb-4">
            Has completado todos los tests y ya solo faltaría la entrevista.
            ¡Tenemos muchas ganas de conocerte!
          </p>
          <p className="text-lg mb-4">
            Recuerda que si tienes que hacer cambios a la fecha y hora de la
            entrevista puedes reprogramarla en el enlace que se indica al final
            del correo de confirmación que has recibido.
          </p>
          <p className="text-lg mb-4">
            Si tienes algún problema puedes siempre escribirnos a{" "}
            <a
              className="underline text-primary"
              rel="noreferrer"
              href="mailto:admissiones@adalab.es"
            >
              admisiones@adalab.es
            </a>
            , y estaremos encantadas de ayudarte.
          </p>
          <p className="text-lg mb-4">
            Feliz día, <br />
            El equipo de Admisiones
          </p>
        </div>
      )}
    </AdmissionProcessStep>
  );
}
