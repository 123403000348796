import { LogoutIcon } from "components/Icons";
import logo from "assets/logo.png";

export function Header({ onSignOut }: HeaderProps) {
  return (
    <div className="bg-primary w-full shadow-md">
      <div className="container h-14 mx-auto px-4 flex items-center justify-between">
        <img src={logo} className="h-4" alt="" />
        <button
          onClick={onSignOut}
          className="bg-white px-2 py-2 rounded-full hover:bg-gray-50 hover:text-red-500"
        >
          <LogoutIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
}

type HeaderProps = {
  onSignOut: () => void;
};
