import { useNavigate } from "react-router-dom";
import { useState } from "react";

import image from "../assets/signIn.svg";
import { auth } from "firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export function AdmissionLoginScreen(): JSX.Element {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasErrors, setHasErrors] = useState(false);

  let login = () => {
    console.log("Email: ", email);
    console.log("Password: ", password);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        sessionStorage.setItem(
          "currentUser",
          JSON.stringify(userCredential.user)
        );

        navigate("/admission-process/data-course/introduction");
      })
      .catch((error) => {
        setHasErrors(true);
      });
  };

  return (
    <div className="bg-primary flex flex-col justify-center items-center h-screen">
      <div className="w-full md:max-w-md px-6 sm:px-16 py-12 sm:mt-24 bg-white rounded-md shadow-md flex justify-center items-center flex-col gap-12">
        <img src={image} className="w-48" alt="" />
        <div>
          <input
            type="text"
            className="w-full mb-4"
            placeholder="Correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            type="text"
            className="w-full mb-8"
            placeholder="Contraseña"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <button
            className="text-center text-white py-2 bg-blue-500 rounded-sm shadow-md w-full"
            onClick={login}
          >
            Acceder
          </button>
        </div>
      </div>
      {hasErrors && (
        <div
          className="mt-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Usuario o contraseña no validos</strong>
        </div>
      )}
    </div>
  );
}
