import { Navigate, useSearchParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "firebase";
import { LoadingScreen } from "screens";

function RequireAdmissionAuth({ children }: { children: JSX.Element }) {
  let [isLoading, setIsLoading] = useState(true);
  let [isAuthenticated, setIsAuthenticated] = useState(false);
  let [searchParams] = useSearchParams();
  let location = useLocation();

  useEffect(() => {
    let currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
    let token = searchParams.get("token") || "";

    if (currentUser.email) {
      setIsAuthenticated(true);
      setIsLoading(false);
    } else {
      signInWithCustomToken(auth, token)
        .then((userCredential) => {
          setIsAuthenticated(true);
          sessionStorage.setItem(
            "currentUser",
            JSON.stringify(userCredential.user)
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return (
      <Navigate to="/admission-process/login" state={{ from: location }} />
    );
  }

  return children;
}

export default RequireAdmissionAuth;
