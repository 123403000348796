import { Checkbox } from "./Checkbox";
import { Fragment, useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

export function Selection({
  className,
  onChange = () => {},
  options,
  placeholder,
  selectAllDefaultValue,
  selectAllText,
}: SelectionProps) {
  const [selected, setSelected] = useState<SelectionOption[]>(options);
  const [selectAllChecked, setSelectAllChecked] = useState(
    selectAllDefaultValue
  );

  useEffect(() => {
    if (selectAllDefaultValue) {
      const nextSelected = selected.map((item) => ({
        ...item,
        selected: selectAllDefaultValue,
        children: item.children?.map((child) => ({
          ...child,
          selected: selectAllDefaultValue,
        })),
      }));

      const changedValues = getSelectedValues(nextSelected);

      setSelected(nextSelected);
      onChange(changedValues);
    }
  }, [onChange, selected, selectAllDefaultValue]);

  const handleChange = (option: SelectionOption, parent?: SelectionOption) => {
    let newOption: SelectionOption = {
      ...option,
      selected: !option.selected,
      children: option.children?.map((child) => ({
        ...child,
        selected: !option.selected,
      })),
    };

    if (parent) {
      const childIndex: number =
        parent.children?.findIndex((item) => item.name === option.name) || 0;

      newOption = {
        ...parent,
        selected: false,
        children: [
          ...(parent.children || []).slice(0, childIndex),
          { ...option, selected: !option.selected },
          ...(parent.children || []).slice(childIndex + 1),
        ],
      };

      newOption.selected =
        newOption.children?.every((item) => item.selected) || false;
    }

    const index = selected.findIndex((item) => item.name === newOption.name);

    let newSelected = [
      ...selected.slice(0, index),
      newOption,
      ...selected.slice(index + 1),
    ];

    const isAllSelected = newSelected.every((item) => item.selected);
    const changedValues = getSelectedValues(newSelected);

    setSelected(newSelected);
    setSelectAllChecked(isAllSelected);
    onChange(changedValues);
  };

  const handleSelectAllChange = () => {
    const next = !selectAllChecked;
    const nextSelected = selected.map((item) => ({
      ...item,
      selected: next,
      children: item.children?.map((child) => ({ ...child, selected: next })),
    }));
    const changedValues = getSelectedValues(nextSelected);

    setSelected(nextSelected);
    setSelectAllChecked(next);
    onChange(changedValues);
  };

  return (
    <Popover className={`relative ${className}`}>
      <Popover.Button className="w-full py-3 pl-5 pr-10 text-left bg-white rounded-md shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm cursor-pointer">
        <span className="block truncate font-medium text-base">
          {selectAllChecked
            ? selectAllText
            : getSelectedNames(selected) || placeholder}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </span>
      </Popover.Button>

      <Popover.Panel className="py-2 absolute z-10 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-md max-h-60 focus:outline-none sm:text-sm">
        {selectAllText && (
          <Checkbox
            className="px-5 py-3 hover:bg-gray-50 text-base"
            name={selectAllText}
            checked={selectAllChecked}
            onChange={() => handleSelectAllChange()}
          />
        )}
        {selected.map((option, index) => (
          <Fragment key={index}>
            <Checkbox
              className="px-5 py-3 hover:bg-gray-50 text-base"
              key={index}
              name={option.name}
              checked={option.selected}
              onChange={() => handleChange(option)}
            />
            {option.children?.map((child, index) => (
              <Checkbox
                className="px-5 pl-12 py-3 hover:bg-gray-50 text-base"
                key={index}
                checked={child.selected}
                name={child.name}
                onChange={() => handleChange(child, option)}
              />
            ))}
          </Fragment>
        ))}
      </Popover.Panel>
    </Popover>
  );
}

type SelectionOption = {
  name: string;
  value: string | string[];
  selected?: boolean;
  children?: SelectionOption[];
};

type SelectionProps = {
  className?: string;
  options: SelectionOption[];
  placeholder: string;
  selectAllDefaultValue?: boolean;
  selectAllText?: string;
  onChange?: (selected: string[]) => void;
};

function getSelectedValues(nextSelected: SelectionOption[]) {
  return nextSelected
    .filter(
      (item) => item.selected || item.children?.some((item) => item.selected)
    )
    .map((item) => {
      if (typeof item.value === "string") {
        return item.value;
      } else {
        return (
          item.children?.map((item) => {
            if (item.selected) {
              return item.value as string;
            } else {
              return undefined;
            }
          }) || []
        );
      }
    })
    .flat()
    .filter((item) => item) as string[];
}

function getSelectedNames(nextSelected: SelectionOption[]) {
  return nextSelected
    .filter(
      (item) => item.selected || item.children?.some((item) => item.selected)
    )
    .map((item) => {
      if (item.selected) {
        return item.name;
      } else {
        return (
          item.children
            ?.map((item) => {
              if (item.selected) {
                return item.name;
              } else {
                return undefined;
              }
            })
            .filter((item) => item) || []
        );
      }
    })
    .flat()
    .join(" | ");
}
