import { IndicatorValue, IndicatorData } from "./IndicatorValue";
import { Indicator } from "./Indicator";

export type State = {
  [key: number]: IndicatorData[];
};

export const Indicators = {
  all: function (data: State, groupBy: string[] = []) {
    return [
      new Indicator(
        "Admitidas confirmadas",
        "Candidatas que han sido admitidas en el curso",
        [new IndicatorValue(data[1], groupBy)],
        "general"
      ),
      new Indicator(
        "Graduadas",
        "Alumnas que han logrado graduarse",
        [new IndicatorValue(data[2], groupBy)],
        "general"
      ),
      new Indicator(
        "Empleables",
        "Alumnas que quieren y pueden trabajar",
        [new IndicatorValue(data[5], groupBy)],
        "general"
      ),
      new Indicator(
        "Insertadas",
        "Alumnas que han encontrado trabajo",
        [new IndicatorValue(data[3], groupBy)],
        "general"
      ),
      new Indicator(
        "Graduación de admitidas",
        "Alumnas admitidas que han logrado graduarse",
        [
          new IndicatorValue(data[2], groupBy),
          new IndicatorValue(data[1], groupBy),
        ],
        "leading",
        true
      ),
      new Indicator(
        "Inserción de admitidas",
        "Alumnas admitidas que han logrado insertarse",
        [
          new IndicatorValue(data[3], groupBy),
          new IndicatorValue(data[1], groupBy),
        ],
        "leading",
        true
      ),
      new Indicator(
        "Inserción de graduadas",
        "Alumnas graduadas que han logrado insertarse",
        [
          new IndicatorValue(data[3], groupBy),
          new IndicatorValue(data[2], groupBy),
        ],
        "leading",
        true
      ),
      new Indicator(
        "Inserción de empleables",
        "Alumnas que quieren y pueden trabajar que han logrado insertarse",
        [
          new IndicatorValue(data[3], groupBy),
          new IndicatorValue(data[5], groupBy),
        ],
        "leading",
        true
      ),
      new Indicator(
        "Salario Medio",
        "Es el salario bruto anual medio del primer empleo",
        [
          new IndicatorValue(data[8], groupBy),
          new IndicatorValue(data[7], groupBy),
        ],
        "general"
      ),
      new Indicator(
        "Días hasta la inserción",
        "Días de media hasta que encuentran el primer empleo",
        [
          new IndicatorValue(data[10], groupBy),
          new IndicatorValue(data[9], groupBy),
        ],
        "working"
      ),
      new Indicator(
        "Meses hasta la inserción",
        "Meses de media hasta que encuentran el primer empleo",
        [
          new IndicatorValue(data[10], groupBy),
          new IndicatorValue(data[9], groupBy),
        ],
        "working",
        false,
        2,
        30
      ),
      new Indicator(
        "Contrato indefinido",
        "Alumnas con contrato indefinido en su primer empleo",
        [
          new IndicatorValue(data[6], groupBy),
          new IndicatorValue(data[3], groupBy),
        ],
        "general",
        true
      ),
      new Indicator(
        "No insertadas",
        "Alumnas que quieren y pueden trabajar que no han encontrado trabajo",
        [new IndicatorValue(data[4], groupBy)],
        "general"
      ),
      new Indicator(
        "Inserción en 2 meses",
        "Alumnas insertadas los primeros 2 meses después de graduarse",
        [
          new IndicatorValue(data[11], groupBy),
          new IndicatorValue(data[5], groupBy),
        ],
        "working",
        true
      ),
      new Indicator(
        "Inserción en 4 meses",
        "Alumnas insertadas entre 2 y 4 meses después de graduarse",
        [
          new IndicatorValue(data[12], groupBy),
          new IndicatorValue(data[5], groupBy),
        ],
        "working",
        true
      ),
      new Indicator(
        "Inserción en 6 meses",
        "Alumnas insertadas entre 4 y 6 meses después de graduarse",
        [
          new IndicatorValue(data[13], groupBy),
          new IndicatorValue(data[5], groupBy),
        ],
        "working",
        true
      ),
      new Indicator(
        "Inserción en 8 meses",
        "Alumnas insertadas entre 6 y 8 meses después de graduarse",
        [
          new IndicatorValue(data[14], groupBy),
          new IndicatorValue(data[5], groupBy),
        ],
        "working",
        true
      ),
      new Indicator(
        "Inserción en 10 meses",
        "Alumnas insertadas entre 8 y 10 meses después de graduarse",
        [
          new IndicatorValue(data[15], groupBy),
          new IndicatorValue(data[5], groupBy),
        ],
        "working",
        true
      ),
      new Indicator(
        "Inserción en más 10 meses",
        "Alumnas insertadas después de 10 meses de graduarse",
        [
          new IndicatorValue(data[16], groupBy),
          new IndicatorValue(data[5], groupBy),
        ],
        "working",
        true
      ),
    ];
  },
  group: function (data: State, group: string, groupBy: string[] = []) {
    return this.all(data, groupBy).filter(
      (indicator) => indicator.group === group
    );
  },
};
