import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdmissionProcessStep from "../AdmissionProcessStep";
import questions from "../technicalTestQuestions.json";
import { Answers } from "./EnglishTest";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";

export default function TechnicalTest(): JSX.Element {
  const navigate = useNavigate();
  const [screen, setScreen] = useState(1);
  const [answers, setAnswers] = useState<Answers>({});
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screen]);

  const handleAnswerChange = (question: string, answer: number) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [question]: answer }));
  };

  const sendResults = async () => {
    const q = query(
      collection(db, "users"),
      where("email", "==", currentUser.email)
    );

    const results = Object.values(answers).reduce((acc, i) => acc + i, 0);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((snapshot) => {
      updateDoc(snapshot.ref, {
        admissionProcess: {
          1: {
            ...(snapshot?.data()?.admissionProcess?.[1] || {}),
            technicalLevel: results,
            progress: 1,
          },
        },
      });
    });
  };

  const handleNextStep = () => {
    sendResults();
    navigate("/admission-process/data-course/english-test");
  };

  return (
    <AdmissionProcessStep onNextStep={handleNextStep}>
      <div className="w-full">
        <p className="text-lg mb-6">
          Para realizar la prueba de Python (duración aproximada de 1h30), debes
          ver este videotutorial y utilizar la plataforma replit para reproducir
          lo que vas a aprender. Una vez termines el tutorial, te pedimos que
          contestes a 12 preguntas, eligiendo la opción correcta en cada una de
          ellas.
        </p>
        <p className="text-lg font-bold mb-3">Pasos a seguir:</p>
        <ul className="text-lg mb-3 list-disc pl-5">
          <li>
            Crea una cuenta en{" "}
            <a
              className="underline text-primary"
              target="_blank"
              rel="noreferrer"
              href="https://replit.com/"
            >
              https://replit.com/
            </a>
          </li>
          <li>
            Empieza el tutorial <b>en el minuto 31 del vídeo</b>
          </li>
          <li>
            Elige la opción correcta en cada una de las cuestiones de la prueba
            que encontrarás más abajo en esta página.
          </li>
        </ul>

        <p className="text-lg mb-6">
          Puedes consultar el tutorial tantas veces sean necesarias para
          contestar a las preguntas.
        </p>
        <p className="text-lg mb-6">¡Mucha suerte!</p>

        <div
          className="w-full relative h-0 pb-10"
          style={{ paddingBottom: "56.25%" }}
        >
          <iframe
            className="w-full h-full left-0 top-0 absolute"
            width="1259"
            height="708"
            src="https://www.youtube.com/embed/OPMCytIzyh8?start=1860"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <p className="text-lg my-6">
          Responde correctamente al siguiente cuestionario.
        </p>
        <div className="">
          {questions.map((question) => {
            return (
              <div key={question.title} className="grid grid-cols-1 gap-6 mt-6">
                <label className="block">
                  <span
                    className="text-gray-700"
                    dangerouslySetInnerHTML={{
                      __html: question.title,
                    }}
                  />
                  <div className="grid grid-cols-1 gap-2 mt-4">
                    {question.answers.map((answer) => {
                      return (
                        <label
                          key={answer.title}
                          className="block items-center"
                        >
                          <input
                            type="radio"
                            name={question.title}
                            value={answer.value}
                            className="border-gray-300 text-primary shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                            onChange={() =>
                              handleAnswerChange(question.title, answer.value)
                            }
                          />
                          <span className="ml-2">{answer.title}</span>
                        </label>
                      );
                    })}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </AdmissionProcessStep>
  );
}
