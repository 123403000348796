import { useNavigate } from "react-router-dom";
import { useAuth } from "ProvideAuth";
import { useIndicators } from "useIndicators";
import {
  Header,
  Indicator,
  IndicatorList,
  LineChart,
  Screen,
  Section,
  Selection,
  Spacer,
  ToolBar,
} from "components";

import { LoadingScreen } from "screens";
import colors from "colors";

export function DashboardScreen(): JSX.Element {
  const navigate = useNavigate();
  const auth = useAuth();
  const {
    filteredIndicators,
    leadingIndicators,
    generalIndicators,
    workingIndicators,
    allIndicators,
    groupIndicatorsByPromos,
    filterIndicatorsByName,
    isLoading,
  } = useIndicators(["M"], ["Admitidas confirmadas"]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Screen
      topBar={
        <Header
          onSignOut={() => {
            auth.sigOut(() => navigate("/"));
          }}
        />
      }
    >
      <ToolBar>
        <Selection
          placeholder="Selecciona una promoción..."
          selectAllText="Todas las promociones"
          onChange={groupIndicatorsByPromos}
          options={[
            {
              name: "Promociones de 2021",
              value: ["M", "L", "K"],
              children: [
                { name: "Mileva", value: "M", selected: true },
                { name: "Lee", value: "L" },
                { name: "Kizzy", value: "K" },
              ],
            },
            {
              name: "Promociones de 2020",
              value: ["J", "I", "H"],
              children: [
                { name: "Jemison", value: "J" },
                { name: "Idelisa", value: "I" },
                { name: "Hamilton", value: "H" },
              ],
            },
            {
              name: "Promociones de 2019",
              value: ["G", "F", "E"],
              children: [
                { name: "Grace", value: "G" },
                { name: "Fairfax", value: "F" },
                { name: "Easley", value: "E" },
              ],
            },
            {
              name: "Promociones anteriores",
              value: ["D", "C", "B", "A"],
              children: [
                { name: "Dorcas", value: "D" },
                { name: "Clarke", value: "C" },
                { name: "Borg", value: "B" },
                { name: "Ada", value: "A" },
              ],
            },
          ]}
        />
      </ToolBar>

      <Section columns={4}>
        {leadingIndicators.map((indicator, index) => (
          <Indicator
            key={index}
            {...indicator}
            value={indicator.value}
            color={Object.values(colors)[index]}
          />
        ))}
      </Section>

      <Section columns={2}>
        <IndicatorList indicators={generalIndicators} />
        <IndicatorList indicators={workingIndicators} />
      </Section>

      <Spacer />

      <ToolBar>
        <Selection
          placeholder="Selecciona un indicador..."
          onChange={filterIndicatorsByName}
          options={allIndicators}
        />
      </ToolBar>

      <Section>
        <LineChart
          data={{
            labels: [
              ["Ada", "2016"],
              ["Borg", "2017"],
              ["Clarke", "2017"],
              ["Dorcas", "2018"],
              ["Easley", "2018"],
              ["Fairfax", "2019"],
              ["Grace", "2019"],
              ["Hamilton", "2019"],
              ["Idelisa", "2020"],
              ["Jemison", "2020"],
              ["Kizzy", "2020"],
              ["Lee", "2021"],
              ["Mileva", "2021"],
            ],
            datasets: filteredIndicators.map((item, index) => {
              return {
                label: item.name,
                data: item.values,
                fill: false,
                backgroundColor: Object.values(colors)[index],
                borderColor: Object.values(colors)[index],
                cubicInterpolationMode: "monotone",
                tension: 0.4,
              };
            }),
          }}
        />
      </Section>
    </Screen>
  );
}
