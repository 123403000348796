import React, { Children, cloneElement, ReactElement } from "react";

export function ToolBar({ children }: ToolBarProps) {
  return (
    <div className="py-1 flex flex-col flex-wrap sm:flex-row justify-end items-center gap-4 sm:gap-6">
      {Children.map(children, (child: React.ReactNode) => {
        return cloneElement(child as ReactElement, {
          className: "w-full sm:max-w-sm",
        });
      })}
    </div>
  );
}
type ToolBarProps = {
  children: React.ReactNode;
};
