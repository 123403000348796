import { useEffect } from "react";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";

export default function Interview(): JSX.Element {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  const sendResults = async () => {
    const q = query(
      collection(db, "users"),
      where("email", "==", currentUser.email)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((snapshot) => {
      updateDoc(snapshot.ref, {
        admissionProcess: {
          1: {
            ...(snapshot?.data()?.admissionProcess?.[1] || {}),
            progress: 3,
          },
        },
      });
    });
  };

  useEffect(() => {
    sendResults()
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="meetings-iframe-container"
      data-src="https://meetings-eu1.hubspot.com/ines-raimundo/entrevista-personal-data-analytics?embed=true"
    />
  );
}
