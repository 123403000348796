import React from "react";
import { SpinnerIcon } from "components";

export function LoadingScreen() {
  return (
    <div className="bg-primary flex flex-col justify-center items-center h-screen">
      <SpinnerIcon className="animate-spin h-12 w-12 text-white" />
    </div>
  );
}
